<template>
  <div :class="isSP ? 'top--sp' : 'top--pc'">
    <!-- トップビジュアル -->
    <sp-visual v-if="isSP" class="top__visual--sp" />
    <pc-visual v-else class="top__visual--pc" />
    <!-- 事業一覧 -->
    <template v-if="isSP">
      <sp-business class="top__business--sp"
                   v-for="business in businesses.slice(0, 4)" :key="business.name  + '/sp'" :business="business" />
    </template>
    <template v-else>
      <pc-business class="top__business--pc"
                   v-for="business in businesses.slice(0, 4)" :key="business.name  + '/pc'" :business="business" :isSingle="false" />
    </template>
    <!-- Instagaram -->
    <sp-instagram v-if="isSP" class="top__instagram--sp" />
    <pc-instagram v-else class="top__instagram--pc" />
    <!-- リクルート/会社概要 -->
    <template v-if="isSP">
      <sp-business class="top__business--sp"
                   v-for="business in businesses.slice(4, 6)" :key="business.name  + '/sp'" :business="business" />
    </template>
    <template v-else>
      <pc-business class="top__business--pc"
                   v-for="business in businesses.slice(4, 6)" :key="business.name  + '/pc'" :business="business" :isSingle="true" />
    </template>
    <!-- 取引先 -->
    <sp-supplier v-if="isSP" class="top__supplier--sp" />
    <pc-supplier v-else class="top__supplier--pc" />
    <!-- アクセス -->
    <sp-access v-if="isSP" ref="address" class="top__access--sp" />
    <pc-access v-else ref="address" class="top__access--pc" />
  </div>
</template>

<script>
import SpVisual from '@/components/top/visual/sp'
import PcVisual from '@/components/top/visual/pc'
import SpBusiness from '@/components/top/business/sp'
import PcBusiness from '@/components/top/business/pc'
import SpInstagram from '@/components/top/instagram/sp'
import PcInstagram from '@/components/top/instagram/pc'
import SpSupplier from '@/components/top/supplier/sp'
import PcSupplier from '@/components/top/supplier/pc'
import SpAccess from '@/components/top/access/sp'
import PcAccess from '@/components/top/access/pc'

export default {
  name: 'top',
  components: { SpVisual, PcVisual, SpBusiness, PcBusiness, SpInstagram, PcInstagram, SpSupplier, PcSupplier, SpAccess, PcAccess },
  mounted () {
    // ページ遷移
    this.$nextTick(() => this.scrollHash())
  },
  watch: {
    hash () {
      this.$nextTick(() => this.scrollHash())
    }
  },
  computed: {
    /**
     * @return {Boolean} SPデバイスかどうか
     */
    isSP () {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      )
    },
    /**
     * @return {String} ハッシュ値
     */
    hash () {
      return this.$route.hash
    },
    /**
     * @return {Object[]} 事業一覧
     */
    businesses () {
      return [
        { name: '車検・整備・修理', img: require('@/assets/img/business/inspection_' + (this.isSP ? 'sp' : 'pc') + '.jpeg'), to: 'inspection' },
        { name: '特殊車両(特装架装)', img: require('@/assets/img/business/special_' + (this.isSP ? 'sp' : 'pc') + '.jpeg'), to: 'special' },
        { name: '板金塗装', img: require('@/assets/img/business/painting_' + (this.isSP ? 'sp' : 'pc') + '.jpeg'), to: 'painting' },
        { name: 'タイヤ・部品販売', img: require('@/assets/img/business/tire_' + (this.isSP ? 'sp' : 'pc') + '.jpeg'), to: 'tire' },
        { name: 'リクルート', img: require('@/assets/img/business/recruit_' + (this.isSP ? 'sp' : 'pc') + '.jpeg'), to: 'recruit' },
        { name: '会社概要', img: require('@/assets/img/business/company_' + (this.isSP ? 'sp' : 'pc') + '.jpeg'), to: 'company' }
      ]
    }
  },
  methods: {
    /**
     * hash位置へスクロール
     */
    scrollHash () {
      if (this.hash) {
        const refName = this.hash.replace('#', '')
        const component = this.$refs[refName]
        component.$el.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';

.top {
  width: 100%;
  &--sp {
    @extend .top;
    margin-top: $sp_header_height;
  }
  &--pc {
    @extend .top;
    margin-top: $pc_header_height;
  }
  &__instagram {
    &--sp {
      @extend .top__instagram;
      margin: 124px auto;
    }
    &--pc {
      @extend .top__instagram;
      margin: 248px auto;
    }
  }
  &__supplier {
    &--sp {
      @extend .top__supplier;
      margin: 95px auto;
    }
    &--pc {
      @extend .top__supplier;
      margin: 131px auto;
    }
  }
  &__access {
    &--sp {
      @extend .top__access;
      margin: 105px auto;
    }
    &--pc {
      @extend .top__access;
      margin: 184px auto;
    }
  }
}
</style>
