import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const modules = {}

export default new Vuex.Store({
  modules: modules,
  state: {
    MENUS: [
      { title: '車検・整備・修理', to: 'inspection' },
      { title: '特殊車両(特装架装)', to: 'special' },
      { title: '板金塗装', to: 'painting' },
      { title: 'タイヤ・部品販売', to: 'tire' },
      { title: 'リクルート', to: 'recruit' },
      { title: '会社概要', to: 'company' },
      { title: '問い合わせ', to: 'contact' }
    ],
    INSTAGRAM: [
      { src: require('@/assets/img/instagram/1.jpeg'), link: 'https://www.instagram.com/p/CT1TbRkJfgp/' },
      { src: require('@/assets/img/instagram/2.jpeg'), link: 'https://www.instagram.com/p/CT1TQtiJRcc/' },
      { src: require('@/assets/img/instagram/3.jpeg'), link: 'https://www.instagram.com/p/CT1TIOCJknG/' },
      { src: require('@/assets/img/instagram/4.jpeg'), link: 'https://www.instagram.com/p/CT1S4NPJj4o/' },
      { src: require('@/assets/img/instagram/5.jpeg'), link: 'https://www.instagram.com/p/CT1Su8rpS48/' },
      { src: require('@/assets/img/instagram/6.jpeg'), link: 'https://www.instagram.com/p/CT1SlirJms0/' },
      { src: require('@/assets/img/instagram/7.jpeg'), link: 'https://www.instagram.com/p/CT1SdSJpher/' },
      { src: require('@/assets/img/instagram/8.jpeg'), link: 'https://www.instagram.com/pCT1SXSlJLC2/' },
      { src: require('@/assets/img/instagram/9.jpeg'), link: 'https://www.instagram.com/p/CT1SSmvpUB8/' }
    ],
    SUPPLIERS: [
      { src: require('@/assets/img/supplier/1.jpeg'), name: '極東開発工業株式会社', link: 'https://www.kyokuto.com' },
      { src: require('@/assets/img/supplier/2.jpeg'), name: '日本トレクス株式会社', link: 'http://www.trex.co.jp/index.html' },
      { src: require('@/assets/img/supplier/3.jpeg'), name: '日野自動車株式会社', link: 'https://www.hino.co.jp' },
      { src: require('@/assets/img/supplier/4.jpeg'), name: 'オナン発電機', link: 'http://www.kkcse.co.jp/dynamo/' }
    ]
  },
  getters: {
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Objcet[]} メニュー情報
     */
    MENUS: state => state.MENUS,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Objcet[]} インスタグラム情報
     */
    INSTAGRAM: state => state.INSTAGRAM,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Objcet[]} 取引先企業情報
     */
    SUPPLIERS: state => state.SUPPLIERS
  },
  mutations: {
  },
  actions: {
    /**
     * 全モジュールのstoreをリセットする
     */
    resetState: ({ commit }) => {
      Object.keys(modules).forEach(key => {
        if (Object.keys(modules[key].state).length !== 0) commit(key + '/resetState')
      })
    }
  }
})
