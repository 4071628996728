<template>
  <div class="top-instagram">
    <h2 class="top-instagram__title" @click="move('https://www.instagram.com/shinei_sharyou/')">INSTAGRAM</h2>
    <div class="top-instagram__list">
      <v-img class="top-instagram__list__item" v-for="image in images" :key="image.src"
             :src="image.src" @click="move(image.link)" />
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  computed: {
    /**
     * @return {Object[]} インスタグラムの画像一覧情報
     */
    images () {
      return this.$store.getters.INSTAGRAM
    }
  },
  methods: {
    /**
     * 指定URLへ別タブで遷移
     * @param {String} url 遷移先URL
     */
    move (url) {
      window.open(url)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.top-instagram {
  width: 80%;
  max-width: 1000px;
  &__title {
    font-family: 'Nunito', 'M PLUS 1p', sans-serif;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    justify-items: center;
    width: 691px;
    margin: 70px auto 0 auto;
    &__item {
      flex: initial;
      width: 213px;
      height: 213px;
      cursor: pointer;
      &:nth-child(n + 4) {
        margin-top: 25px;
      }
    }
  }
}
</style>
