import Vue from 'vue'
import VueGtag from 'vue-gtag'
import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'

import '@babel/polyfill'
import 'whatwg-fetch'

Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV !== 'production'

/**
 * GATAGの設定
 * @see https://matteo-gabriele.gitbook.io/vue-gtag
 */
const gtagID = process.env.VUE_APP_GTAG_ID
if (gtagID) {
  Vue.use(VueGtag, {
    config: { id: gtagID }
  }, router)
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
