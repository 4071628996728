<template>
  <div class="top-visual" id="visual">
    <v-img class="top-visual__background"
           :lazy-src="require('@/assets/img/top/mobile_first.jpeg')"
           src="https://storage.googleapis.com/shinei-sharyou.appspot.com/top/mobile.gif" cover>
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-5"></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <v-img class="top-visual__scroll" :src="require('@/assets/img/scroll.svg')" contain />
  </div>
</template>

<script>
export default {
  mounted () {
    // スマホのときはアドレスバー分高さがずれるので、修正する
    const size = require('@/assets/sass/size.scss')
    const headerHeight = parseInt(size.sp_header_height)
    const innerHeight = window.innerHeight
    document.getElementById('visual').style.height = (innerHeight - headerHeight) + 'px'
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.top-visual {
  position: relative;
  width: 100vw;
  height: calc(100vh - #{$sp_header_height});
  margin-right: calc(((100vw - 100%) / 2) * -1);
  margin-left: calc(((100vw - 100%) / 2) * -1);
  &__background {
    width: 100vw;
    height: 100%;
  }
  &__scroll {
    position: absolute;
    right: 15px;
    bottom: -15px;
    z-index: $popup_zindex;
    width: auto;
    height: 30px;
  }
}
</style>
