<template>
  <div class="top-access">
    <h2 class="top-access__title">アクセス</h2>
    <!-- 地図 -->
    <p class="top-access__map"><iframe class="top-access__map__iframe"
                                       src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3336.758890277977!2d131.7369562151941!3d33.246619480832784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x354427956f42149b%3A0xfacae45f48243fd9!2z44K344Oz44Ko44Kk6LuK6LyM77yI5pyJ77yJ!5e0!3m2!1sja!2sjp!4v1629704977810!5m2!1sja!2sjp"
                                       style="border: 0;" allowfullscreen="" loading="lazy"></iframe></p>
    <!-- 情報 -->
    <h3 class="top-access__qualification">民間車検場（九州運輸局指定）</h3>
    <v-img class="top-access__logo" :src="require('@/assets/img/logo/access.svg')" contain  />
    <h4 class="top-access__service">《自動車整備・タイヤ販売・極東部品販売》</h4>
    <p class="top-access__address">
      〒870-0266  大分県大分市大在2番地<br>
      TEL 097-547-7723 / FAX 097-547-7724
    </p>
    <!-- お問い合わせ -->
    <a class="top-access__contact" href="https://docs.google.com/forms/d/e/1FAIpQLSeW1JiNP1NiKgTnnMxFXwSQ9bXZzgSAbh6fVgrYn072Ua85ow/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer">問い合わせ</a>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.top-access {
  width: 90%;
  max-width: 1000px;
  &__title {
    font-size: 2.5rem;
    font-weight: 500;
    text-align: center;
  }
  &__map {
    width: 828px;
    max-width: 100%;
    height: 352px;
    margin: 32px auto 0 auto;
    &__iframe {
      width: 100%;
      height: 100%;
    }
  }
  &__qualification {
    margin-top: 103px;
    font-size: 1.5rem;
    font-weight: normal;
    text-align: center;
  }
  &__logo {
    width: auto;
    height: 30px;
    margin: 35px auto 0 auto;
  }
  &__service {
    margin-top: 50px;
    font-size: 1.5rem;
    font-weight: normal;
    text-align: center;
  }
  &__address {
    margin-top: 25px;
    font-size: 1.5rem;
    line-height: 2.2rem;
    text-align: center;
  }
  &__contact {
    display: block;
    width: 155px;
    height: 50px;
    margin: 75px auto 0 auto;
    font-size: 1.5rem;
    line-height: 5rem;
    color: $white_color !important;
    text-align: center;
    background-color: $blue_color;
    border-radius: 128px;
  }
}
</style>
