/**
 * original function library
 * [usage]
 * import scroll from '@/assets/lib/scroll'
 * mixins: [scroll]
 */
import Vue from 'vue'

export default Vue.extend({
  methods: {
    /**
     * スクロール動作の停止 / 再開
     * @param {Boolean} isStop
     */
    controlScroll (isStop) {
      isStop ? this.bodyScrollPrevent(true) : this.bodyScrollPrevent(false)
    },
    /**
     * スクロール制御（内部関数、ここ以外のファイルで使用しない）
     * @see https://shanabrian.com/web/javascript/get-scroll-position.php
     * @param {*} isStop
     */
    bodyScrollPrevent (isStop) {
      let scrollPosition
      const body = document.getElementsByTagName('body')[0]
      // スクロールバーの幅
      const scrollBarWidth = window.innerWidth - document.body.clientWidth

      if (isStop) {
        body.style.paddingRight = scrollBarWidth + 'px'
        scrollPosition = -window.pageYOffset
        body.style.position = 'fixed'
        body.style.width = '100%'
        body.style.top = scrollPosition + 'px'
      } else {
        body.style.paddingRight = ''
        scrollPosition = parseInt(body.style.top.replace(/[^0-9]/g, ''))
        body.style.position = ''
        body.style.width = ''
        body.style.top = ''
        window.scrollTo(0, scrollPosition)
      }
    }
  }
})


