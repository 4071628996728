<template>
  <div class="top-visual" id="visual">
    <!-- 動画 -->
    <iframe class="top-visual__iframe"
            src="https://www.youtube.com/embed/lDTwzAs65aI?autoplay=1&rel=0&playsinline=1&mute=1&controls=0&loop=1&playlist=lDTwzAs65aI"
            frameborder="0" allowfullscreen></iframe>
    <!-- スクロール -->
    <v-img class="top-visual__scroll" :src="require('@/assets/img/scroll.svg')" contain />
  </div>
</template>

<script>
export default {
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.top-visual {
  position: relative;
  width: 100vw;
  height: calc(100vh - #{$pc_header_height});
  margin-right: calc(((100vw - 100%) / 2) * -1);
  margin-left: calc(((100vw - 100%) / 2) * -1);
  &__iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    margin-top: -60px;
    height: calc(100vh + 120px);
    transform: translate(-50%, -50%);
    @media (min-aspect-ratio: 16/9) {
      height: 56.25vw;
    }
    @media (max-aspect-ratio: 16/9) {
      width: calc((100vh + 120px) / (9 / 16));
    }
  }
  &__scroll {
    position: absolute;
    right: 37px;
    bottom: -26px;
    z-index: $popup_zindex;
    width: auto;
    height: 52px;
  }
}
</style>
