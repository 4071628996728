import Vue from 'vue'
import Router from 'vue-router'
import Top from '@/views/Top.vue'


/**
 * vue-router v3.1.0から、同一画面に遷移するとコンソールエラーが発生するようになった
 * push関数にエラーハンドリングを追加して、デフォルトの関数を上書きすることで対応
 * @see https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
 */
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = Router.prototype.replace
Router.prototype.replace = function replace (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'top',
      component: Top
    },
    {
      path: '/inspection',
      name: 'inspection',
      component: loadComponent('Inspection.vue')
    },
    {
      path: '/special',
      name: 'special',
      component: loadComponent('Special.vue')
    },
    {
      path: '/painting',
      name: 'painting',
      component: loadComponent('Painting.vue')
    },
    {
      path: '/tire',
      name: 'tire',
      component: loadComponent('Tire.vue')
    },
    {
      path: '/recruit',
      name: 'recruit',
      component: loadComponent('Recruit.vue')
    },
    {
      path: '/company',
      name: 'company',
      component: loadComponent('Company.vue')
    },
    {
      path: '**',
      redirect: { name: 'top' }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

/**
 * viewsのファイルをロード
 * @param {String} name viewsのファイル名
 * @return {Object} 遅延ロードしたコンポーネント
 */
function loadComponent (name) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${name}`)
}

/**
 * ページ遷移の分岐処理
 */
router.beforeEach((to, from, next) => {
  next()
})

export default router
