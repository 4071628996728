<template>
  <v-app id="app" class="app">
    <sp-header v-if="isSP" class="app__header" />
    <pc-header v-else class="app__header" />
    <router-view class="app__main" />
    <sp-footer v-if="isSP" class="app__footer" />
    <pc-footer v-else class="app__footer" />
  </v-app>
</template>

<script>
import SpHeader from '@/components/common/header/sp'
import PcHeader from '@/components/common/header/pc'
import SpFooter from '@/components/common/footer/sp'
import PcFooter from '@/components/common/footer/pc'

export default {
  components: { SpHeader, PcHeader, SpFooter, PcFooter },
  mounted () {
  },
  computed: {
    /**
     * @return {Boolean} SPデバイスかどうか
     */
    isSP () {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      )
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

html {
  font-size: 10px !important;
}

#app {
  font-family: 'M PLUS 1p', 'Nunito', 'Roboto', 'Noto Sans JP', 'Avenir', Helvetica, Arial, sans-serif;
  color: $blue_color;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: $blue_color !important;
  text-decoration: none;
  outline: none;
}

.app {
  width: 100vw;
  & > .v-application--wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 100vh;
    text-align: justify;
  }
  &__main {
    width: 100%;
  }
  &__footer {
    align-self: flex-end;
  }
}
</style>
