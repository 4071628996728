<template>
  <div v-on:mouseover="isMouseOver = true" v-on:mouseleave="isMouseOver = false"
       :class="isSingle ? 'top-business' : 'top-business--double'" @click="$router.push({ name: business.to })">
    <v-img :class="isMouseOver ? 'top-business__background' : 'top-business__background--active'" :src="business.img" cover />
    <h2 class="top-business__name">{{ business.name }}</h2>
  </div>
</template>

<script>
export default {
  props: {
    // 事業情報
    business: {
      type: Object,
      required: true
    },
    // 1列かどうか
    isSingle: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      // マウスオーバーの有無
      isMouseOver: false
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.top-business {
  position: relative;
  width: 100vw;
  height: 400px;
  cursor: pointer;
  &--double {
    @extend .top-business;
    display: inline-block;
    width: 50vw;
    height: 460px;
    vertical-align: middle;
  }
  &__background {
    width: 100%;
    height: 100%;
    &--active {
      @extend .top-business__background;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: '';
        background-color: rgba(#056eb0, 0.3);
      }
    }
  }
  &__name {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 3rem;
    font-weight: normal;
    color: #fffef9;
    text-align: center;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
</style>
