<template>
  <footer class="footer">
    <p class="footer__text">
      © シンエイ車輌有限会社<br>
      〒870-0266<br>
      大分県大分市大字大在２<br>
      Tel: 097-547-7723<br>
    </p>
    <v-img class="footer__logo" :src="require('@/assets/img/logo/footer.svg')" contain
           @click="$router.push({ name: 'top' })"/>
  </footer>
</template>

<script>
export default {
  computed: {
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.footer {
  z-index: $footer_zindex;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: space-between;
  width: 100vw;
  padding: 21px 25px;
  color: $white_color;
  background-color: $blue_color;
  &__text {
    flex: 1;
    margin: 0;
  }
  &__logo {
    flex: initial;
    width: auto;
    height: 17px;
  }
}
</style>
