<template>
  <div class="top-business" @click="$router.push({ name: business.to })">
    <v-img class="top-business__background" :src="business.img" cover />
    <h2 class="top-business__name">{{ business.name }}</h2>
  </div>
</template>

<script>
export default {
  props: {
    // 事業情報
    business: {
      type: Object,
      required: true
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.top-business {
  position: relative;
  width: 100vw;
  height: 155px;
  &__background {
    width: 100%;
    height: 100%;
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
      background-color: rgba(#056eb0, 0.3);
    }
  }
  &__name {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 0;
    margin: 0;
    font-size: 1.8rem;
    font-weight: normal;
    color: #fffef9;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
</style>
