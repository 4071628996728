<template>
  <div class="top-supplier">
    <h2 class="top-supplier__title">指定協力工場</h2>
    <div class="top-supplier__list">
      <div class="top-supplier__list__item" v-for="supplier in suppliers" :key="supplier.name"
           @click="move(supplier.link)">
        <v-img class="top-supplier__list__item__img" :src="supplier.src" contain />
        <p class="top-supplier__list__item__name">{{ supplier.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  computed: {
    /**
     * @return {Object[]} 取引先企業一覧
     */
    suppliers () {
      return this.$store.getters.SUPPLIERS
    }
  },
  methods: {
    /**
     * 指定URLへ別タブで遷移
     * @param {String} url 遷移先URL
     */
    move (url) {
      window.open(url)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.top-supplier {
  width: 90%;
  max-width: 1000px;
  &__title {
    font-size: 2.5rem;
    font-weight: 500;
    text-align: center;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    justify-items: start;
    width: 783px;
    margin: 98px auto 0 auto;
    &__item {
      flex: initial;
      width: 50%;
      text-align: center;
      cursor: pointer;
      &:nth-child(n + 3) {
        margin-top: 134px;
      }
      &__img {
        width: auto;
        max-width: 80%;
        height: 60px;
        margin: 0 auto;
      }
      &__name {
        margin: 30px 0 0;
        font-size: 1.8rem;
      }
    }
  }
}
</style>
