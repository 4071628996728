<template>
  <header :class="!isOpen ? 'header' : 'header--open'">
    <!-- メニューアイコン -->
    <v-btn v-if="!isOpen" class="header__menu" @click="doOpen(true)" icon>
      <v-img class="header__menu__icon" :src="require('@/assets/img/menu/open.svg')" />
    </v-btn>
    <v-btn v-else class="header__menu" @click="doOpen(false)" icon>
      <v-img class="header__menu__icon" :src="require('@/assets/img/menu/close.svg')" />
    </v-btn>
    <!-- ロゴ -->
    <v-img v-if="!isOpen" class="header__logo" :src="require('@/assets/img/logo/header_white.svg')" @click="$router.push({ name: 'top' })" contain />
    <v-img v-else class="header__logo" :src="require('@/assets/img/logo/header_blue.svg')" @click="$router.push({ name: 'top' })" contain />
    <!-- 地図アイコン -->
    <a class="header__map" href="https://goo.gl/maps/8GqdZw2RoZJ19niS6" target="_blank" rel="noopener noreferrer">
      <v-img v-if="!isOpen" class="header__map__icon" :src="require('@/assets/img/map/white.svg')" />
      <v-img v-else class="header__map__icon" :src="require('@/assets/img/map/blue.svg')" />
    </a>
    <transition name="fade"><header-menu v-if="isOpen" @do-open="doOpen" /></transition>
  </header>
</template>

<script>
import scroll from '@/assets/lib/scroll'

import HeaderMenu from '@/components/common/header/menu/pc'

export default {
  mixins: [scroll],
  components: { HeaderMenu },
  data () {
    return {
      // メニューの開閉状況
      isOpen: false
    }
  },
  computed: {
  },
  methods: {
    /**
     * メニューの開閉
     * @param {Boolean} isOpen 開閉有無
     */
    doOpen (isOpen) {
      this.controlScroll(isOpen)
      this.isOpen = isOpen
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $header_zindex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: $pc_header_height;
  padding: 0 16px;
  background-color: $blue_color;
  &--open {
    @extend .header;
    background-color: $white_color;
  }
  &__menu {
    flex: initial;
    &.v-btn--icon.v-size--default {
      width: 22px;
      height: auto;
    }
  }
  &__logo {
    flex: initial;
    width: 198px;
    height: auto;
  }
  &__map {
    flex: initial;
    &__icon {
      width: 15px;
      height: auto;
    }
  }
}

// アニメーション
.fade-enter-active,
.fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
