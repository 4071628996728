<template>
  <div class="header-menu">
    <ul class="header-menu__list">
      <li v-on:mouseover="active = menu.to" v-on:mouseleave="active = pageName"
          :class="active === menu.to ? 'header-menu__list__element--active' : 'header-menu__list__element'"
          v-for="menu in menus" :key="menu.title"
          @click="doMove(menu.to)">{{ menu.title }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // 活性中の名前
      active: ''
    }
  },
  mounted () {
    // 最初は表示中のページを代入
    this.active = this.pageName
  },
  computed: {
    /**
     * @return {String} ページ名
     */
    pageName () {
      return this.$route.name
    },
    /**
     * @return {Object[]} メニュー一覧
     */
    menus () {
      return this.$store.getters.MENUS
    }
  },
  methods: {
    /**
     * 遷移してメニューを閉じる
     * @param {String} to 遷移先
     */
    doMove (to) {
      // 問い合わせの場合とそれ以外で挙動を変える
      if (to === 'contact') {
        this.$router.push({ name: 'top', hash: '#address' })
      } else {
        this.$router.push({ name: to })
      }
      this.$emit('do-open', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.header-menu {
  position: fixed;
  top: $pc_header_height;
  left: 0;
  z-index: $processing_zindex;
  justify-content: center;
  width: 100vw;
  height: calc(100vh - #{$pc_header_height});
  background-color: $white_color;
  &__list {
    width: 100%;
    padding: 0;
    margin-top: 50px;
    font-size: 1.5rem;
    text-align: center;
    list-style: none;
    &__element {
      width: 100%;
      padding: 17px 0;
      cursor: pointer;
      border-radius: 10px;
      &--active {
        @extend .header-menu__list__element;
        color: $white_color;
        background-color: $blue_color;
      }
    }
  }
}
</style>
